.usa-section {
  @include border-box-sizing;
  @include u-padding-y($theme-site-margins-width);

  @include at-media("tablet") {
    @include u-padding-y(8);
  }
}

.usa-section--light {
  background-color: color("base-lightest");
}

.usa-section--dark {
  background-color: color("primary-darker");
  color: color("white");

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: color("accent-cool");
  }

  p {
    color: color("white");
  }

  a {
    color: color($theme-link-reverse-color);

    &:hover {
      color: color($theme-link-reverse-hover-color);
    }

    &:active {
      color: color($theme-link-reverse-active-color);
    }
  }
}
