// Variables
$triangle-size: 5px;

/* Tooltips */
.usa-tooltip {
  display: inline-block;
  position: relative;
}

.usa-tooltip__trigger {
  cursor: pointer;

  > svg {
    display: block;
    pointer-events: none;
  }
}

.usa-tooltip__body {
  transition: opacity 0.08s ease-in-out;
  background-color: color($theme-tooltip-background-color);
  border-radius: radius($theme-button-border-radius);
  bottom: 0;
  color: color($theme-tooltip-font-color);
  display: none;
  font-size: size("ui", $theme-tooltip-font-size);
  padding: units(1);
  position: absolute;
  pointer-events: none;
  left: 0;
  opacity: 0;
  transform: translateX(-50%);
  width: auto;
  white-space: pre;
  z-index: 100000;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    pointer-events: none;
    border-left: $triangle-size solid transparent;
    border-right: $triangle-size solid transparent;
    border-top: $triangle-size solid color($theme-tooltip-background-color);
    position: absolute;
    bottom: -$triangle-size;
    left: 50%;
    margin-left: -$triangle-size;
  }
}

.usa-tooltip__body--wrap {
  width: 100%;
  white-space: normal;
}

.usa-tooltip__body.is-set {
  display: block;
}

.usa-tooltip__body.is-visible {
  opacity: 1;
}

.usa-tooltip__body--bottom {
  bottom: auto;
  top: 0;

  &:after {
    border-left: $triangle-size solid transparent;
    border-right: $triangle-size solid transparent;
    border-bottom: $triangle-size solid color($theme-tooltip-background-color);
    border-top: 0;
    bottom: auto;
    top: -$triangle-size;
  }
}

.usa-tooltip__body--right {
  top: auto;
  transform: translateX(0);

  &:after {
    border-top: $triangle-size solid transparent;
    border-bottom: $triangle-size solid transparent;
    border-right: $triangle-size solid color($theme-tooltip-background-color);
    border-left: 0;
    right: auto;
    top: 50%;
    bottom: 0;
    left: -$triangle-size;
    margin: -$triangle-size 0 0 0;
  }
}

.usa-tooltip__body--left {
  top: auto;
  left: 0;
  right: auto;
  transform: translateX(0);

  &:after {
    border-top: $triangle-size solid transparent;
    border-bottom: $triangle-size solid transparent;
    border-left: $triangle-size solid color($theme-tooltip-background-color);
    border-right: 0;
    right: -$triangle-size;
    top: 50%;
    bottom: 0;
    left: auto;
    margin: -$triangle-size 0 0 0;
  }
}
